import React, { useState, useEffect } from 'react';
import { Loader, User } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const RegisteredUsers = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchRegisteredUsers();
  }, []);

  const fetchRegisteredUsers = async () => {
    try {
      const response = await fetch('/api/registered-users'); // Adjust endpoint as needed
      if (!response.ok) {
        throw new Error('Failed to fetch registered users');
      }
      const data = await response.json();
      setUsers(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-black flex items-center justify-center">
        <Loader className="w-8 h-8 text-blue-500 animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-black flex items-center justify-center">
        <div className="bg-red-900 text-white p-4 rounded">
          {error}
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-black p-4">
      <div className="max-w-4xl mx-auto">
        {/* Header Section */}
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-4xl font-bold text-white">
            Registered Users
          </h1>
          <button
            onClick={() => navigate(-1)}
            className="px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded"
          >
            Back
          </button>
        </div>

        {/* Registered Users List */}
        {users.length === 0 ? (
          <div className="text-gray-400 text-center py-12">
            <p className="text-xl">No registered users found.</p>
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {users.map((user) => (
              <div
                key={user.id}
                className="bg-gray-800 rounded-lg p-4 flex items-center space-x-4 cursor-pointer hover:bg-gray-700 transition-colors"
              >
                <User className="w-10 h-10 text-white" />
                <div>
                  <h2 className="text-xl text-white">{user.name}</h2>
                  <p className="text-gray-400">{user.phone}</p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default RegisteredUsers;
