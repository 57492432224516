// lib/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyBH4Dv9IN7TMjJmdGr9jF-xtnPE0GboW1M",
  authDomain: "coopgisv03.firebaseapp.com",
  projectId: "coopgisv03",
  storageBucket: "coopgisv03.firebasestorage.app",
  messagingSenderId: "257648722540",
  appId: "1:257648722540:web:2c89e2bcb19aa2392a8e49",
  measurementId: "G-K4V4T79TQ4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
auth.languageCode = 'en'; // Set the language of SMS if needed

export { auth };
