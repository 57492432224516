import React, { useState, useEffect, useRef } from 'react';
import { Crop, Clock, MapPin, Loader } from 'lucide-react';
import { getSegmentation } from './utils';
import { useAuth } from '../AuthContext'; // Import useAuth from AuthContext


const PreviewScreen = ({
  photo,
  location,
  timestamp,
  cropName,
  additionalPhotos,
  resetForm,
  onSuccess,
  setSubmitSuccess
}) => {
  const mounted = useRef(true);
  const { phoneNumber } = useAuth(); // Use phoneNumber from AuthContext
  
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isGettingSegmentation, setIsGettingSegmentation] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [address, setAddress] = useState(''); // State to hold the address
  console.log('PreviewScreen phoneNumber', phoneNumber);

  useEffect(() => {
    mounted.current = true;

    const fetchAddress = async () => {
      try {
        const apiKey = 'AIzaSyCIZ177yevqsrHflhN5MrOoHJ9PahdKROc'; // Your API key
        const fetchedAddress = await getAddressFromLatLong(location.latitude, location.longitude, apiKey);
        if (mounted.current) {
          setAddress(fetchedAddress);
        }
      } catch (error) {
        console.error('Error fetching address:', error);
        if (mounted.current) {
          setAddress('Unable to fetch address');
        }
      }
    };
    fetchAddress();


    return () => {
      mounted.current = false;
    };
  }, [location.latitude, location.longitude]);

  const getAddressFromLatLong = async (latitude, longitude, apiKey) => {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`
    );
    const data = await response.json();
  
    if (data.status === 'OK' && data.results.length > 0) {
      return data.results[0].formatted_address; // Return the formatted address
    } else {
      throw new Error('Failed to get address from coordinates');
    }
  };

  const handleSubmit = async () => {
    try {
      if (!mounted.current) return;
      setIsSubmitting(true);
      setSubmitError('');
      
      if (!phoneNumber) {
        throw new Error('Phone number not found. Please login again.');
      }
  
      // Get the farm boundary data from the segmentation function
      const boundaryData = await getSegmentation(
        location.latitude,
        location.longitude,
        0.000005,
        18,
        setIsGettingSegmentation
      );


  
      // Farm creation request data
      const farmData = {
        location: {
          latitude: location.latitude,
          longitude: location.longitude,
          accuracy: location.accuracy,
          address:address
          
        },
        timestamp,
        cropName,
        phoneNumber: phoneNumber,
        farmBoundary: boundaryData
      };
  
      // Step 1: Create the farm
      const farmResponse = await fetch('/api/farms', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(farmData)
      });
  
      if (!farmResponse.ok) {
        const errorData = await farmResponse.json();
        throw new Error(errorData.detail || 'Error creating farm');
      }
      
      console.log('farmResponse', farmResponse);

      const farm = await farmResponse.json();
      const farmId = farm.id;
  
      // Step 2: Create the contribution
      const contributionData = {
        farm_id: farmId,
        user_id: phoneNumber,  // Assuming user_id is the phone number
        photo_base64: photo  // Send the photo for the contribution
      };
  
      const contributionResponse = await fetch('/api/contributions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(contributionData)
      });

      console.log('contributionResponse', contributionResponse);
  
      if (!contributionResponse.ok) {
        const errorData = await contributionResponse.json();
        throw new Error(errorData.detail || 'Error creating contribution');
      }
  
      const contribution = await contributionResponse.json();
      console.log('Contribution created:', contribution);
  
      // Mark submission success and call onSuccess callback
      setSubmitSuccess(true);
      setTimeout(() => {
        if (onSuccess) {
          onSuccess();
        }
      }, 1500);
  
    } catch (error) {
      if (mounted.current) {
        setSubmitError(error.message || 'Failed to submit registration');
      }
    } finally {
      if (mounted.current) {
        setIsSubmitting(false);
      }
    }
  };
  

  return (
    <div className="space-y-3 border-2 border-green-700 p-6 rounded-lg shadow-lg mt-8">
      <div className=" rounded-lg overflow-hidden mb-4 shadow-lg">
      <img src={photo} alt="Farm" className="w-48 h-48 mx-auto object-cover rounded-lg shadow-lg mt-0" />
      </div>

      <div className="bg-gray-900 rounded-lg p-4 mx-auto mb-4 shadow-lg">
        <h2 className="text-white font-semibold mx-auto mb-3">Farm Details</h2>
        <div className="space-y-2 text-sm text-gray-300">
          <div className="flex items-center">
            <Crop className="w-4 h-4 mr-2" />
            <span>Crop: {cropName}</span>
          </div>
          
          <div className="flex items-center">
            <MapPin className="w-4 h-4 mr-2" />
            <span>Location: {address || 'Loading address...'} (±{Math.round(location.accuracy)}m)</span>
          </div>
        </div>
      </div>

      {submitError && (
        <div className="bg-red-900 text-white p-3 rounded-lg mb-4 shadow-lg">
          {submitError}
        </div>
      )}

      <div className="space-y-3">
        <button
          onClick={handleSubmit}
          disabled={isSubmitting || isGettingSegmentation}
          className={`w-full bg-blue-500 text-white py-3 rounded-lg font-semibold hover:bg-blue-600 transition-all flex items-center justify-center ${
            (isSubmitting || isGettingSegmentation) ? 'opacity-50 cursor-not-allowed' : ''
          }`}
        >
          {isGettingSegmentation ? (
            <>
              <Loader className="w-5 h-5 animate-spin mr-2" />
              Getting Farm Boundary...
            </>
          ) : isSubmitting ? (
            <>
              <Loader className="w-5 h-5 animate-spin mr-2" />
              Submitting...
            </>
          ) : (
            'Confirm and Submit'
          )}
        </button>
        {!isSubmitting && (
          <button
            onClick={resetForm}
            className="w-full bg-gray-700 text-white py-3 rounded-lg font-semibold hover:bg-gray-600 transition-all"
          >
            Retake Photo
          </button>
        )}
      </div>
    </div>
  );
};

export default PreviewScreen;