import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Loader } from 'lucide-react';
import { RecaptchaVerifier, signInWithPhoneNumber, onAuthStateChanged  } from 'firebase/auth';
import { auth } from './firebase';
import icon from './leaf.png';
import { useAuth } from './AuthContext'; // Importing useAuth to access the global state


const AuthFlow = ({ onSuccess }) => {
  const { setPhoneNumber, setUser } = useAuth(); // Get the phone number and user from AuthContext
  const [showOTP, setShowOTP] = useState(false);
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [recaptchaVerified, setRecaptchaVerified] = useState(false);
  const recaptchaRef = useRef(null);
  const verifierRef = useRef(null);
  const [enteredPhoneNumber, setEnteredPhoneNumber] = useState(''); // Local state for phone number input


  const initializeRecaptcha = useCallback(async () => {
    try {

      if (verifierRef.current) {
        // Avoid reinitializing the reCAPTCHA if already initialized
        return;
      }

      verifierRef.current = new RecaptchaVerifier(auth, recaptchaRef.current, {
        size: 'normal',
        callback: () => {
          console.log('reCAPTCHA verified');
          setRecaptchaVerified(true);
          setError('');
        },
        'expired-callback': () => {
          console.log('reCAPTCHA expired');
          setRecaptchaVerified(false);
          setError('reCAPTCHA expired. Please verify again.');
          verifierRef.current.render(); // Re-render reCAPTCHA if it expires
        },
      });

      await verifierRef.current.render();
    } catch (error) {
      console.error('Error initializing reCAPTCHA:', error);
      setError('Failed to initialize verification system. Please refresh the page.');
    }
  }, []);

  useEffect(() => {
    initializeRecaptcha();

    const authObserver = auth.onAuthStateChanged((user) => {
      if (user && !showOTP) {
        setUser(user);
        setPhoneNumber(user.phoneNumber.replace('+91', ''));
      }
    });

    return () => authObserver();
  }, [showOTP, setUser, setPhoneNumber, initializeRecaptcha]);
  
  const validatePhoneNumber = (number) => {
    const phoneRegex = /^[6-9]\d{9}$/;
    return phoneRegex.test(number);
  };

  const handleSendOTP = async () => {
    if (!validatePhoneNumber(enteredPhoneNumber)) {
      setError('Please enter a valid 10-digit Indian phone number');
      return;
    }

    if (!recaptchaVerified) {
      setError('Please complete the reCAPTCHA verification');
      return;
    }

    setLoading(true);
    setError('');

    try {
      const formattedNumber = `+91${enteredPhoneNumber}`;
      
      if (!verifierRef.current) {
        throw new Error('reCAPTCHA not initialized');
      }

      const confirmation = await signInWithPhoneNumber(
        auth, 
        formattedNumber, 
        verifierRef.current
      );
      
      setConfirmationResult(confirmation);
      setShowOTP(true);
      console.log("OTP sent to phone number:", enteredPhoneNumber);
      setError('');
    } catch (err) {
      console.error('Error sending OTP:', err);
      setError(getErrorMessage(err.code));
      
      // Reset reCAPTCHA
      if (verifierRef.current) {
        verifierRef.current.render(); 
        setRecaptchaVerified(false);
      }

    } finally {
      setLoading(false);
    }
  };

  const handleOTPChange = (index, value) => {
    if (value === '' || /^\d$/.test(value)) {
      const newOTP = [...otp];
      newOTP[index] = value;
      setOtp(newOTP);

      // Auto-focus next input
      if (value !== '' && index < 5) {
        const nextInput = document.querySelector(`input[name=otp-${index + 1}]`);
        if (nextInput) nextInput.focus();
      }
    }
  };

  const handleOTPVerify = async (e) => {
    e.preventDefault();
    
    if (!confirmationResult) {
      setError('Please request OTP first');
      return;
    }

    const otpValue = otp.join('');
    if (otpValue.length !== 6) {
      setError('Please enter complete OTP');
      return;
    }

    setLoading(true);
    setError('');

    try {
      const result = await confirmationResult.confirm(otpValue);
      const phoneNumber = result.user.phoneNumber.replace('+91', ''); // Extract phone number from user object

      setUser(result.user);
      setPhoneNumber(phoneNumber); // Store phone number in AuthContext
      // Store user in AuthContext
      setShowOTP(false);
      onSuccess(phoneNumber);
    } catch (err) {
      console.error('Error verifying OTP:', err);
      if (err.code === 'auth/invalid-verification-code') {
        setError('Invalid OTP. Please check and try again.');
      } else if (err.code === 'auth/code-expired') {
        setError('OTP has expired. Please request a new one.');
        setShowOTP(false);
        await initializeRecaptcha();
      } else {
        setError('Failed to verify OTP. Please try again.');
      }
      setOtp(['', '', '', '', '', '']);
    } finally {
      setLoading(false);
    }
  };



  const handleChangeNumber = async () => {
    setShowOTP(false);
    setOtp(['', '', '', '', '', '']);
    setError('');
    setEnteredPhoneNumber('');

    setConfirmationResult(null);
    setRecaptchaVerified(false);
    
    // Re-initialize reCAPTCHA
    await initializeRecaptcha();
  };

  const handleSuccessfulAuth = async (user) => {
    try {
      // Extract phone number from user object and remove +91 prefix
      const formattedPhoneNumber  = user.phoneNumber.replace('+91', '');
      
      // Create profile
      setPhoneNumber(formattedPhoneNumber); // Store phone number in AuthContext
      // Call the original onSuccess callback
      setUser(user); // Store user in AuthContext

      onSuccess(formattedPhoneNumber);
    } catch (error) {
      console.error('Error in post-authentication setup:', error);
      setError('Failed to complete setup. Please try again.');
      
      // Optionally sign out the user if profile creation fails
      await auth.signOut();
    }
  };

  const getErrorMessage = (errorCode) => {
    switch (errorCode) {
      case 'auth/invalid-phone-number':
        return 'Invalid phone number format. Please check your number.';
      case 'auth/too-many-requests':
        return 'Too many attempts. Please try again later.';
      case 'auth/operation-not-allowed':
        return 'Phone authentication is not enabled. Please contact support.';
      case 'auth/captcha-check-failed':
        return 'reCAPTCHA verification failed. Please try again.';
      default:
        return 'Failed to send OTP. Please try again.';
    }
  };

  return (
    <div className="min-h-screen bg-black flex flex-col items-center justify-center p-4">
      {/* Logo and Title */}
      <div className="flex flex-col items-center mb-12">
        <img 
          src={icon} 
          alt="Logo" 
          className="w-16 h-16 mb-4 object-contain"
        />
        <h1 className="text-5xl font-bold">
          <span className="text-white">coop</span>
          <span className="text-blue-500">farms</span>
        </h1>
        <p className="text-gray-400 text-sm mt-2">
    by Cooptem Technologies Private Limited
  </p>
      </div>

      {/* Main Container */}
      <div className="w-full max-w-md">
        {!showOTP ? (
          // Phone Number Form
          <form onSubmit={(e) => { e.preventDefault(); handleSendOTP(); }} className="space-y-6">
            <div>
              <div className="flex items-center space-x-2">
                <span className="text-white px-3 py-3 bg-gray-900 rounded-lg border border-gray-700">
                  +91
                </span>
                <input
                  type="tel"
                  value={enteredPhoneNumber}
                  onChange={(e) => {
                    const value = e.target.value.replace(/\D/g, '').slice(0, 10);
                    setEnteredPhoneNumber(value);
                    setError('');
                  }}
                  placeholder="Enter your phone number"
                  className="flex-1 px-4 py-3 rounded-lg bg-gray-900 text-white border border-gray-700 focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none transition-colors"
                  disabled={loading}
                />
              </div>
              
              {/* Error Message */}
              {error && <p className="mt-2 text-red-500 text-sm">{error}</p>}
            </div>

            {/* reCAPTCHA Container */}
            <div className="flex justify-center">
              <div ref={recaptchaRef} />
            </div>
            
            {/* Submit Button */}
            <button
              type="submit"
              className={`w-full bg-blue-500 text-white py-3 rounded-lg font-semibold transition-colors flex items-center justify-center ${
                loading || enteredPhoneNumber.length !== 10 || !recaptchaVerified
                  ? 'opacity-50 cursor-not-allowed'
                  : 'hover:bg-blue-600'
              }`}
              disabled={loading || enteredPhoneNumber.length !== 10 || !recaptchaVerified}
            >
              {loading ? (
                <Loader className="w-5 h-5 animate-spin" />
              ) : (
                'Send OTP'
              )}
            </button>
          </form>
        ) : (
          // OTP Verification Form
          <form onSubmit={handleOTPVerify} className="space-y-6">
            <div>
              <label className="text-white text-sm font-medium mb-2 block">
                Enter verification code sent to +91 {enteredPhoneNumber}
              </label>
              
              {/* OTP Input Grid */}
              <div className="grid grid-cols-6 gap-2 mt-2">
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    type="text"
                    name={`otp-${index}`}
                    value={digit}
                    onChange={(e) => handleOTPChange(index, e.target.value)}
                    maxLength={1}
                    className="w-full h-12 text-center text-xl font-semibold bg-gray-900 text-white border border-gray-700 rounded-lg focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                    disabled={loading}
                  />
                ))}
              </div>
              
              {/* Error Message */}
              {error && <p className="mt-2 text-red-500 text-sm">{error}</p>}
              
              {/* Change Number Link */}
              <button
                type="button"
                onClick={handleChangeNumber}
                className="mt-2 text-blue-500 text-sm hover:text-blue-400 transition-colors"
                disabled={loading}
              >
                Change phone number
              </button>
            </div>

            {/* Verify Button */}
            <button
              type="submit"
              className={`w-full bg-blue-500 text-white py-3 rounded-lg font-semibold transition-colors flex items-center justify-center ${
                loading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600'
              }`}
              disabled={loading || otp.some(digit => digit === '')}
            >
              {loading ? (
                <Loader className="w-5 h-5 animate-spin" />
              ) : (
                'Verify OTP'
              )}
            </button>
          </form>
        )}
      </div>
      {/* Footer */}
      <footer className="mt-12 text-center text-gray-400 text-sm">
        <p>&copy; {new Date().getFullYear()} Cooptem Technologies Private Limited. All rights reserved.</p>
        <p className="mt-2">Innovating for a sustainable trade.</p>
      </footer>
    </div>
  );
};

export default AuthFlow;

