import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, GeoJSON, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

// Fix for Leaflet marker icons not displaying
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png').default,
  iconUrl: require('leaflet/dist/images/marker-icon.png').default,
  shadowUrl: require('leaflet/dist/images/marker-shadow.png').default,
});

// Custom hook to refresh Leaflet map when component is mounted
const RefreshMap = () => {
  const map = useMap();
  useEffect(() => {
    setTimeout(() => {
      map.invalidateSize(); // ✅ Fixes map not rendering issue
    }, 500);
  }, [map]);
  return null;
};

const VillageMap = () => {
  const [geojsonData, setGeojsonData] = useState(() => {
    const storedGeoJSON = sessionStorage.getItem("geojson");
    return storedGeoJSON ? JSON.parse(storedGeoJSON) : null;
  });

  const [loading, setLoading] = useState(!geojsonData);
  const [refreshKey, setRefreshKey] = useState(0); // ✅ Force re-render on navigation
  const navigate = useNavigate();

  useEffect(() => {
    if (!geojsonData) {
      fetchGeoJSON();
    }
  }, []);

  const fetchGeoJSON = async () => {
    try {
      const response = await fetch('/grape_fields.geojson');
      const data = await response.json();

      // ✅ Fix incorrect coordinate order (Swap [lat, lon] → [lon, lat])
      const correctedGeoJSON = {
        ...data,
        features: data.features.map(feature => ({
          ...feature,
          geometry: {
            ...feature.geometry,
            coordinates: feature.geometry.coordinates.map(polygon =>
              polygon.map(point => [point[1], point[0]]) // Swap lat/lon
            )
          }
        }))
      };

      console.log("Corrected GeoJSON:", correctedGeoJSON); // Debugging
      setGeojsonData(correctedGeoJSON);
      sessionStorage.setItem("geojson", JSON.stringify(correctedGeoJSON)); // ✅ Cache data
      setLoading(false);
    } catch (error) {
      console.error('Error loading GeoJSON:', error);
      setLoading(false);
    }
  };

  // ✅ Force map to re-render when navigating back
  useEffect(() => {
    setRefreshKey(prevKey => prevKey + 1);
  }, []);

  return (
    <div className="relative h-screen w-screen">
      {/* 🔙 Back Button */}
      <button 
        onClick={() => navigate(-1)} 
        className="absolute top-4 left-4 z-10 bg-gray-800 text-white p-2 rounded-full shadow-md hover:bg-gray-700"
      >
        <ArrowLeft className="w-5 h-5" />
      </button>

      {loading ? (
        <div className="flex items-center justify-center h-screen text-white text-xl">
          Loading Map...
        </div>
      ) : (
        <MapContainer
          key={refreshKey} // ✅ Forces re-render when navigating back
          center={[17.08, 74.77]}  // Adjust based on your farm data
          zoom={15}
          style={{ height: '100vh', width: '100%' }}
        >
          <RefreshMap />  {/* Forces Leaflet to re-render on navigation */}

          {/* 🗺️ OpenStreetMap Tile Layer */}
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
          />
          {/* 🌍 Mapbox Satellite Layer */}
          <TileLayer
                    url="https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/512/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoic2hpbnRyZSIsImEiOiJjbHVhdmphbzUwbWF0MnFtbThhc3Z2MjZ4In0.-jeFD1_aqZvnrXpHZ8qT5Q"
                    attribution="© Mapbox"
          />
          
          {/* 🟣 Render Grape Field Polygons */}
          {geojsonData && (
            <GeoJSON
              data={geojsonData}
              style={() => ({
                color: "#ff0000",  // 🔴 Red border for visibility
                weight: 2,
                fillColor: "#ff0000", // 🔴 Red fill
                fillOpacity: 0.4,  // Semi-transparent
              })}
            />
          )}
        </MapContainer>
      )}
    </div>
  );
};

export default VillageMap;
